<template>
  <div>
    <div style="margin: 10px 0">
      <el-input style="width: 200px" placeholder="请输入物料号" suffix-icon="el-icon-search" v-model="compnum"></el-input>
      <el-input style="width: 200px" placeholder="请输入库位" suffix-icon="el-icon-search" v-model="location"></el-input>
      <el-input style="width: 200px" placeholder="请输入名称" suffix-icon="el-icon-search" v-model="name"></el-input>
      <el-select filterable clearable v-model="userId" placeholder="负责人">
        <el-option v-for="item in users" :key="item.nickname" :label="item.nickname" :value="item.id"></el-option>
      </el-select>
      <el-button class="ml-5" type="primary" @click="load">搜索</el-button>
      <el-button type="warning" @click="reset">重置</el-button>
    </div>

    <div style="margin: 10px 0">
      <el-button type="primary" @click="handleAdd" v-if="user.role!=='ROLE_READ'" >新增 <i class="el-icon-circle-plus-outline"></i></el-button>
      <el-popconfirm
          class="ml-5"
          confirm-button-text='确定'
          cancel-button-text='我再想想'
          icon="el-icon-info"
          icon-color="red"
          title="您确定批量删除这些数据吗？"
          @confirm="delBatch"
      >
        <el-button type="danger" slot="reference" v-if="user.role!=='ROLE_READ'" >批量删除 <i class="el-icon-remove-outline"></i></el-button>
      </el-popconfirm>
      <el-upload action="http://39.106.92.106:9090/component/import" :show-file-list="false" accept="xlsx" :on-success="handleExcelImportSuccess" style="display: inline-block">
        <el-button type="primary" class="ml-5" v-if="user.role!=='ROLE_READ'" >导入 <i class="el-icon-bottom"></i></el-button>
      </el-upload>
      <el-button type="primary" @click="exp" class="ml-5" v-if="user.role!=='ROLE_READ'" >导出 <i class="el-icon-top"></i></el-button>
      <el-button type="primary" plain @click="updateComp" v-if="user.role!=='ROLE_READ'" >备件领用入库</el-button>
    </div>

    <div style="margin: 10px">
      <span>总金额: {{ formatNumber(totalPrice) }}元</span>
      <span style="margin-left: 20px">有价种类: {{ formatNumber(totalAmount) }}种</span>
    </div>
    <el-table :data="tableData" border stripe :header-cell-class-name="'headerBg'"  @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="id" label="ID" width="80"></el-table-column>
      <el-table-column prop="compnum" label="物料号"></el-table-column>
      <el-table-column prop="location" label="库位"></el-table-column>
      <el-table-column prop="name" label="备件名"></el-table-column>
      <el-table-column
          prop="price"
          label="单价(元)"
          :formatter="formatPrice"
      ></el-table-column>
      <el-table-column prop="sum" label="当前库存数(个)"></el-table-column>
      <el-table-column label="主责任人">
        <template v-slot="scope">
          <span>{{ engineers.find(v => v.id === scope.row.userId) ? engineers.find(v => v.id === scope.row.userId).nickname : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="次责任人">
        <template v-slot="scope">
          <span>{{ getEngNames(scope.row.compnum)  }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="safenum" label="安全库存数(个)"></el-table-column>
      <el-table-column label="车间">
        <template v-slot="scope">
          <span>{{ lines.find(v => v.id === scope.row.lineId) ? lines.find(v => v.id === scope.row.lineId).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="设备">
        <template v-slot="scope">
          <span>{{ equipments.find(v => v.id === scope.row.equipmentId) ? equipments.find(v => v.id === scope.row.equipmentId).name : ''  }}</span>
        </template>
      </el-table-column>
      <el-table-column label="图片">
        <template v-slot="scope">
          <el-image
              v-if="scope.row.imageUrl"
              style="width: 100px; height: 100px"
              :src="scope.row.imageUrl"
              :preview-src-list="[scope.row.imageUrl]"
          ></el-image>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">

          <el-button type="success" @click="handleEdit(scope.row)" v-if="user.role!=='ROLE_READ'" >编辑 <i class="el-icon-edit"></i></el-button>
          <el-popconfirm
              class="ml-5"
              confirm-button-text='确定'
              cancel-button-text='我再想想'
              icon="el-icon-info"
              icon-color="red"
              title="您确定删除吗？"
              @confirm="del(scope.row.id)"
          >
            <el-button type="danger" slot="reference" v-if="user.role!=='ROLE_READ'" >删除 <i class="el-icon-remove-outline"></i></el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <div style="padding: 10px 0">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-sizes="[2, 5, 10, 20]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>

    <el-dialog title="信息" :visible.sync="dialogFormVisible" width="35%" >
      <el-form label-width="100px" size="small">
        <el-form-item label="ID">
          <el-input v-model="form.id" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="物料号">
          <el-input v-model="form.compnum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="库位">
          <el-input v-model="form.location" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="单价(元)">
          <el-input v-model="form.price" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="库存数">
          <el-input v-model="form.sum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="安全库存">
          <el-input v-model="form.safenum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="主负责人">
          <el-select filterable clearable v-model="form.userId" placeholder="主负责人">
            <el-option v-for="item in engineers" :key="item.nickname" :label="item.nickname" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="次责任人">
          <template>
            <span>{{ getEngNames(form.compnum)  }}</span>          <el-button type="primary" plain @click="handleComponentUser(form.compnum)">编辑次负责人 <i class="el-icon-document"></i></el-button>
          </template>
        </el-form-item>
        <el-form-item label="车间">
          <el-select filterable clearable v-model="form.lineId" placeholder="车间">
            <el-option v-for="item in lines" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备">
          <el-select filterable clearable v-model="form.equipmentId" placeholder="设备">
            <el-option v-for="item in equipments" :key="item.name" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片" v-if="form.imageUrl">
          <el-image
              style="width: 100px; height: 100px"
              :src="form.imageUrl"
              :preview-src-list="[form.imageUrl]">
          </el-image>
        </el-form-item>
        <el-form-item label="上传图片" label-width="100px">
          <el-upload class="avatar-uploader" action="http://39.106.92.106:9090/files/upload" :show-file-list="true"
                     :on-success="handleAvatarSuccess" style="display: inline-block" :multiple="false">
            <el-button type="flex" justify="center">上传图片 </el-button>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog title="备件领用入库" :visible.sync="dialogFormVisible2" width="30%" >
      <el-form label-width="120px" size="large" class="formStar">
<!--        <el-form-item label="零件号" prop="compnum">-->
<!--          <el-input placeholder="请输入零件号" v-model="form.compnum" @input="updateCompname"></el-input>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="库位" class="custom-form-item">-->
<!--          <span style="font-size: 18px">{{form.location}}</span>-->
<!--        </el-form-item>-->
        <el-form-item label="物料号" class="custom-form-item">
          <!--            <el-input placeholder="请输入物料号" v-model="form.compnum" @input="updateCompname"></el-input>-->
          <el-autocomplete
              class="inline-input"
              v-model="form.compnum"
              :fetch-suggestions="querySearch"
              placeholder="请输入物料号"
              :trigger-on-focus="false"
              @input="updateCompname"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="库位" class="custom-form-item">
          <!--            <span style="font-size: 18px">{{form.location}}</span>-->
          <!--            <el-input placeholder="请输入库位号" v-model="form.location" @input="updateCompname1"></el-input>-->
          <el-autocomplete
              class="inline-input"
              v-model="form.location"
              :fetch-suggestions="querySearch1"
              placeholder="请输入库位"
              :trigger-on-focus="false"
              @input="updateCompname1"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="零件名" class="custom-form-item">
          <span style="font-size: 18px">{{form.compname}}</span>
        </el-form-item>
        <el-form-item label="单价" class="custom-form-item">
          <span style="font-size: 18px">{{form.price}}元</span>
        </el-form-item>
        <el-form-item label="数量">
          <el-input placeholder="请输入数量" v-model="form.num"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save2" size="large">入库</el-button>
        <el-button type="primary" @click="save1" size="large" style="margin-left: 60px">领用</el-button>
      </div>
    </el-dialog>

    <el-dialog title="编辑次负责人" :visible.sync="dialogFormVisible3" width="35%" :close-on-click-modal="false">
      <el-form label-width="100px" size="small" style="width: 90%">
        <el-form-item label="物料号">
          <el-input v-model="form2.compnum" disabled autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="次负责人">
          <el-select v-model="form2.selectedUsers" multiple filterable placeholder="请选择">
            <el-option
                v-for="item in engineers"
                :key="item.id"
                :label="item.nickname"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible3 = false">取 消</el-button>
        <el-button type="primary" @click="saveComponentUser">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "Component",
  data() {
    return {
      tableData: [],
      users: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      name: "",
      compnum: "",
      location: "",
      userId: null,
      form: {},
      dialogFormVisible: false,
      dialogFormVisible2: false,
      dialogFormVisible3: false,
      multipleSelection: [],
      components: [],
      lines: [],
      equipments: [],
      componentUsers: [],
      engineers: [],
      form2: {
        selectedUsers: [], // 存储选中的用户ID
        compnum: "",
      },
      baseURLdata: request.defaults.baseURL,
      user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
      totalAmount: 0,
      totalPrice: 0,
    }
  },
  created() {
    this.loadState()
    this.load()
  },
  methods: {
    load() {
      this.request.get("/component/page", {
        params: {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.name,
          compnum: this.compnum,
          location: this.location,
          userId: this.userId
        }
      }).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
      this.request.get("/user").then(res => {
        this.users = res.data
        this.engineers = this.users.filter(item => item.role === "ROLE_MAINENG" || item.role === "ROLE_EQUENG" || item.role === "ROLE_FORMAN");
      });
      this.request.get("/component/caculate").then(res => {
        this.totalAmount = res.data.totalAmount
        this.totalPrice = res.data.totalPrice
      });
      this.request.get("/componentUser").then(res => {
        this.componentUsers = res.data
      });
      this.request.get("/line").then(res => {
        this.lines = res.data
      });
      this.request.get("/equipment").then(res => {
        this.equipments = res.data
      });
      this.saveState()
    },
    loadComponent(){
      this.request.get("/component").then(res => {
        this.components = res.data;
      });
    },
    updateComp(){
      this.loadComponent();
      this.dialogFormVisible2 = true;
    },
    saveState() {
      const state = {
        compnum: this.compnum,
        location: this.location,
        name: this.name,
        userId: this.userId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      sessionStorage.setItem('ComponentState', JSON.stringify(state));
    },
    loadState() {
      const savedState = sessionStorage.getItem('ComponentState');
      if (savedState) {
        const { compnum, location, name, userId, pageNum, pageSize } = JSON.parse(savedState);
        this.compnum = compnum || '';
        this.location = location || '';
        this.name = name || '';
        this.userId = userId || null;
        this.pageNum = pageNum || 1;
        this.pageSize = pageSize || 10;
      }
    },
    save() {
      this.request.post("/component", this.form).then(res => {
        if (res.code === '200') {
          this.$message.success("保存成功")
          this.dialogFormVisible = false
          this.load()
        } else {
          this.$message.error("保存失败")
        }
      })
    },
    save1() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');

      const formData = {
        sum: this.form.num,
        compnum: this.form.compnum,
      };
      const formData2 = {
        num: this.form.num,
        compnum: this.form.compnum,
        name: this.form.compname,
        location: this.form.location,
        userId: this.user.id,
        userName: this.users.find(item => item.id === this.user.id).nickname,
        takeTime: formattedRecordTime,
      };
        if (this.form.compnum && this.form.location && this.form.num) {
          this.request.post("/component/updateSum", formData).then(res => {
            if (res.code === '200') {
              this.$message.success("领用成功")
              this.request.post("/componentUpdate/declineSum", formData2)
              this.form = {};
              this.load();
            } else {
              this.$message.error("领用失败")
            }
          })
        }else{
          this.$message.error("不可为空")
        }
    },
    save2() {
      const recordTime = new Date().toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai', // 设置为中国标准时间
        hour12: false, // 24小时制
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).replace(/\//g, '-').replace(',', '');

      // 将时间格式化为 "YYYY-MM-DD HH:MM:SS"
      const formattedRecordTime = recordTime.replace(/(\d{4})-(\d{2})-(\d{2})/, '$1-$2-$3').replace(/(\d{2}):(\d{2}):(\d{2})/, '$1:$2:$3');

      console.log(formattedRecordTime); // 输出格式为 "YYYY-MM-DD HH:MM:SS"
      const formData = {
        sum: this.form.num,
        compnum: this.form.compnum,
      };
      const formData2 = {
        num: this.form.num,
        compnum: this.form.compnum,
        name: this.form.compname,
        location: this.form.location,
        userId: this.user.id,
        userName: this.users.find(item => item.id === this.user.id).nickname,
        takeTime: formattedRecordTime,
      };
      if (this.form.compnum && this.form.location && this.form.num) {
        this.request.post("/component/addSum", formData).then(res => {
          if (res.code === '200') {
            this.$message.success("入库成功")
            this.request.post("/componentUpdate/addSum", formData2)
            this.form = {};
            this.load();
          } else {
            this.$message.error("入库失败")
          }
        })
      }else{
        this.$message.error("不可为空")
      }
    },
    handleAvatarSuccess(res) {
      this.form.imageUrl = res.data
    },
    updateCompname() {
      const component = this.components.find(item => item.compnum === this.form.compnum);
      this.form.compname = component ? component.name : '';
      this.form.price = component ? component.price : '';
      this.form.location = component ? component.location : '';
    },
    updateCompname1() {
      const component = this.components.find(item => item.location === this.form.location);
      this.form.compname = component ? component.name : '';
      this.form.price = component ? component.price : '';
      this.form.compnum = component ? component.compnum : '';
    },
    querySearch(queryString, cb) {
      var results = queryString
          ? this.components
              .filter(this.createFilter(queryString))
              .map(component => ({ value: component.compnum }))
          : [];
      cb(results);
    },
    createFilter(queryString) {
      return (component) => {
        return (component.compnum.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    querySearch1(queryString, cb) {
      var results = queryString
          ? this.components
              .filter(this.createFilter1(queryString))
              .map(component => ({ value: component.location }))
          : [];
      cb(results);
    },
    createFilter1(queryString) {
      return (component) => {
        return (component.location.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleAdd() {
      this.dialogFormVisible = true
      this.form = {}
    },
    handleEdit(row) {
      this.form = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    getUserName(userId) {
      const user = this.users.find(v => v.id === userId);
      if (!user) return '';
      return user ? user.nickname : '';
    },
    handleComponentUser(compnum){
      this.form2 = {
        selectedUsers: this.getEngIds(compnum) || [],// 存储选中的用户ID
        compnum: compnum,
      }
      this.dialogFormVisible3 = true;
    },
    saveComponentUser(){
      this.request.post("/componentUser/handle", this.form2).then(res => {
        if (res.code === '200') {
          this.$message.success("修改成功")
          this.dialogFormVisible3 = false
          this.load()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    getEngIds(compnum) {
      const compUser = this.componentUsers.filter(item => item.compnum === compnum);
      console.log(compUser.map(item => item.userId))
      return compUser.map(item => item.userId);
    },
    getEngNames(compnum) {
      const compUser = this.componentUsers.filter(item => item.compnum === compnum);
      return compUser.map(item => {
        const user = this.users.find(u => u.id === item.userId);
        return user ? user.nickname : '';
      }).join(', ');
    },
    del(id) {
      this.request.delete("/component/" + id).then(res => {
        if (res.code === '200') {
          this.$message.success("删除成功")
          this.load()
        } else {
          this.$message.error("删除失败")
        }
      })
    },
    handleSelectionChange(val) {
      console.log(val)
      this.multipleSelection = val
    },
    formatNumber(number) {
      return number.toLocaleString();
    },
    formatPrice(row, column, value) {
      return Math.floor(value); // 取整
    },
    delBatch() {
      let ids = this.multipleSelection.map(v => v.id)  // [{}, {}, {}] => [1,2,3]
      this.request.post("/component/del/batch", ids).then(res => {
        if (res.code === '200') {
          this.$message.success("批量删除成功")
          this.load()
        } else {
          this.$message.error("批量删除失败")
        }
      })
    },
    reset() {
      this.name = ""
      this.userId = null
      this.compnum = ""
      this.location = ""
      this.load()
      sessionStorage.removeItem('ComponentState');
    },
    handleSizeChange(pageSize) {
      console.log(pageSize)
      this.pageSize = pageSize
      this.load()
    },
    handleCurrentChange(pageNum) {
      console.log(pageNum)
      this.pageNum = pageNum
      this.load()
    },
    exp() {
      window.open(this.baseURLdata+"/component/export")
    },
    handleExcelImportSuccess() {
      this.$message.success("导入成功")
      this.load()
    },
  }
}
</script>


<style>
.headerBg {
  background: #eee!important;
}
</style>
